import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop, compact } from 'lodash';

import './form-field.scss';

export class FormField extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
    type: PropTypes.string,
    isValid: PropTypes.bool,
    isTouched: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    tag: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType]),
    isOptional: PropTypes.bool,
    hidden: PropTypes.bool,
    placeholder: PropTypes.string,
    ariaDescribedBy: PropTypes.string,
    ariaInvalid: PropTypes.bool,
    fieldSetAndLegend: PropTypes.bool,
    legendStyle: PropTypes.shape({}),
    addon: PropTypes.node, // For EVAL-3276
    resetTouchOnChange: PropTypes.bool,
    useFloatingLabel: PropTypes.bool,
    floatingPosition: PropTypes.oneOf([null, 'on-border', 'under-border']), // use with `useFloatingLabel` prop
    ariaDescribedByAdditional: PropTypes.string,
    readOnly: PropTypes.bool,
    isLiveRegion: PropTypes.bool,
    inputTagRef: PropTypes.shape({}),
  };
  static defaultProps = {
    tag: 'input',
    type: 'text',
    isValid: true,
    isTouched: false,
    onBlur: null,
    onChange: null,
    onFocus: noop,
    className: '',
    labelClassName: 'small ps-0_25',
    inputClassName: '',
    isOptional: false,
    hidden: false,
    placeholder: '',
    label: null,
    ariaDescribedBy: '',
    ariaInvalid: false,
    fieldSetAndLegend: false,
    legendStyle: null,
    addon: undefined,
    resetTouchOnChange: false,
    useFloatingLabel: false,
    floatingPosition: null,
    ariaDescribedByAdditional: '',
    readOnly: false,
    isLiveRegion: false,
    inputTagRef: undefined,
  };

  state = {
    touched: this.props.isTouched,
  };

  onBlur = e => {
    e.persist();
    const touched = this.getTouchedValue();
    this.setState(
      {
        touched,
      },
      () => {
        if (typeof this.props.onBlur === 'function') {
          this.props.onBlur(e, this.props.name);
        }
      }
    );
  };

  onChange = e => {
    const { onChange, name, resetTouchOnChange } = this.props;

    if (typeof onChange === 'function') {
      onChange(e, name);
    }

    if (resetTouchOnChange && this.state.touched) {
      this.setState({
        touched: false,
      });
    }
  };

  onFocus = () => {
    const { onFocus, name } = this.props;
    onFocus(name);
  };

  getTouchedValue = () => !(this.props.isOptional && !this.value());

  setValue = value => {
    this.input.value = value;
    return value;
  };

  getLabelTag = props => (
    <label
      className={classnames('form-label', props.labelClassName, {
        'form-control-label size-16': props.useFloatingLabel,
      })}
      htmlFor={props.id}
    >
      {props.label}
    </label>
  );

  getAriaParams = (ariaInvalid, ariaDescribedBy, ariaDescribedByAdditional) => {
    const { id, isLiveRegion } = this.props;
    const liveRegionId = isLiveRegion ? `status-${id}` : '';
    const ariaDescribedByStr = compact([ariaDescribedByAdditional, liveRegionId])
      .join(' ')
      .trim();

    if (ariaInvalid && (ariaDescribedBy || ariaDescribedByStr)) {
      return {
        'aria-describedby': `${ariaDescribedBy} ${ariaDescribedByStr}`.trim(),
        'aria-invalid': true,
      };
    } else if (!ariaInvalid && ariaDescribedByStr) {
      return {
        'aria-describedby': ariaDescribedByStr,
      };
    }
    return {};
  };

  getInputTag = props => {
    const {
      id,
      name,
      type,
      isValid,
      inputClassName,
      tag: InputTag,
      ariaDescribedBy,
      ariaInvalid,
      touched,
      resetTouchOnChange,
      ariaDescribedByAdditional,
      inputTagRef,
      ...args
    } = props;

    return (
      <InputTag
        {...args}
        ref={input => {
          this.input = input;
          if (inputTagRef && !inputTagRef.current) inputTagRef.current = input;
        }}
        id={id}
        className={classnames(
          'form-control',
          {
            touched,
            'is-invalid': !isValid,
            'is-valid': touched && isValid,
          },
          inputClassName
        )}
        name={name}
        type={type}
        onChange={this.onChange}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        {...this.getAriaParams(ariaInvalid, ariaDescribedBy, ariaDescribedByAdditional)}
      />
    );
  };

  touch = () => this.setState({ touched: this.getTouchedValue() });

  value = () => this.input.value;

  focus = () => this.input.focus();

  reset = () => {
    this.input.value = '';
    this.setState({
      touched: false,
    });
  };

  render() {
    const {
      id,
      name,
      label,
      type,
      isValid,
      className,
      labelClassName,
      inputClassName,
      isTouched,
      isOptional,
      hidden,
      tag: InputTag,
      ariaDescribedBy,
      ariaInvalid,
      fieldSetAndLegend,
      legendStyle,
      addon,
      resetTouchOnChange,
      useFloatingLabel,
      floatingPosition,
      isLiveRegion,
      ...args
    } = this.props;

    const { touched } = this.state;
    const labelTagParams = { id, label, labelClassName, useFloatingLabel };
    const inputTagParams = {
      id,
      name,
      type,
      isValid,
      inputClassName,
      tag: InputTag,
      ariaDescribedBy,
      ariaInvalid,
      touched,
      ...args,
    };

    return (
      <div
        className={classnames('form-group', className, {
          'has-danger': !isValid,
          'has-success': touched && isValid,
          [`floating-label pos-r floating-${floatingPosition || 'on-border'}`]: useFloatingLabel,
        })}
        hidden={hidden}
      >
        {fieldSetAndLegend ? (
          <fieldset>
            {label && <legend style={legendStyle}>{this.getLabelTag(labelTagParams)}</legend>}
            {this.getInputTag(inputTagParams)}
          </fieldset>
        ) : (
          <Fragment>
            {!useFloatingLabel && label && this.getLabelTag(labelTagParams)}
            {this.getInputTag(inputTagParams)}
            {isLiveRegion && (
              <p className="visually-hidden" role="status" id={`status-${id}`}>
                {touched && isValid && `The ${name} is valid'`}
              </p>
            )}
            {useFloatingLabel && label && this.getLabelTag(labelTagParams)}
            {addon}
          </Fragment>
        )}
      </div>
    );
  }
}
